export const SURVEY_USER_ANSWER_WITH_SURVEY_FRAGMENT = `
  fragment surveyUserAnswerWithSurveyFragment on SurveyUserAnswer {
    uid
    label
    creationTime
    survey {
      uid
    }
  }
`;
